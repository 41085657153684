<!--
 * @Description: 全部商品页面组件(包括全部商品,商品分类,商品搜索)
 * @Author: hai-27
 * @Date: 2020-02-07 16:23:00
 * @LastEditors: hai-27
 * @LastEditTime: 2020-03-08 12:11:13
 -->
<template>
	<div class="goods width1225" id="goods" name="goods">
		<!-- 面包屑 -->
		<div class="breadcrumb">
			<el-breadcrumb separator-class="el-icon-arrow-right">
				<el-breadcrumb-item>
					<router-link to="/">首页</router-link>
				</el-breadcrumb-item>
				<el-breadcrumb-item>
					<router-link to="/goods">全部商品</router-link>
				</el-breadcrumb-item>
				<el-breadcrumb-item v-if="search">搜索</el-breadcrumb-item>
				<el-breadcrumb-item v-else>分类</el-breadcrumb-item>
				<el-breadcrumb-item v-if="search">{{ search }}</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<!-- 面包屑END -->



		<!-- 分类 -->
		<div class="filter-section">
			<div class="filter-item">
				<h3 class="text">元器件分类：</h3>
				<div class="datas">
					<p class="fl data boxsiz lb-box" v-for="item in categoryList" :key="item.Id">
						<a href="javascript:[0]" class="line-block" v-if="item.ChildrenList"
							@click="categoryClick(item)">{{item.Title}}</a>
						<a href="javascript:[0]" class="line-block" v-else>{{item.Title}}</a>
					</p>


				</div>
			</div>
		</div>
		<!-- <div class="filter-section" v-if="fenleiType==1">
			<div class="filter-item">
				<h3 class="text">{{categoryClickInfo.Title}}分类：</h3>
				<div class="datas">
					<p class="fl data boxsiz lb-box" v-for="item in categoryClickInfo.ChildrenList" :key="item.Id" @click="categoryClick2(item)">
						<a href="javascript:[0]" class="line-block" :title="item.Title">{{item.Title}}</a>
					</p>
		
		
				</div>
			</div>
		</div> -->
		<!-- 分类end -->

		<!-- 型号 -->
		<div class="search-datas">
			<div class="search-data">
				<div class="data-article">
					<div class="header clear">
						<div class="boxsiz fl common-td common-td-132">型号</div>
						<div class="boxsiz fl common-td common-td-128">品牌/供应商</div>
						<div class="boxsiz fl common-td common-td-110">库存</div>
						<div class="boxsiz fl common-td common-td-100">起订量/倍数</div>
						<div class="boxsiz fl common-td common-td-140">货期</div>
						<div class="boxsiz fl common-td common-td-140">数量</div>
						<div class="boxsiz fl common-td common-td-100">操作</div>
					</div>
					<!-- 渲染商品列表 -->
					<ul class="body-div-wrapper" v-if="hotGoods">
						<li class="body-div clear " data-skuid="116425773112938" data-supplier="13" data-alert="0"
							data-gyscode="G11308" data-price="0.0441" data-model="FI-XC3A-1-15000" data-kc="16777215"
							data-time="2022-05-20 09:46:13" v-for="(item, index) in hotGoods" :key="index">
							<router-link :to="{ path: '/goods/details' , query: { id: item.Id } }">
								<div class="boxsiz fl common-td common-td-132 model-td">
									<strong class="model">
										<div class="copy-icon"></div>
										{{ item.Name }}
									</strong>
									<!-- <a class="classify" title="矩形连接器触头采购">矩形连接器触头</a> -->
									<div class="down-div lb-box">
										<div class="icon-div  line-block">
											<i class="iconfont iconicons_search_zoomimage  va-m"></i>
										</div>

									</div>

									<div class="tool">
									</div>

								</div>
								<div class="boxsiz fl common-td common-td-128 zzs-td" data-name='JAE Electronics'>
									<p>
										{{ item.BrandInfo.Name }}
									</p>
									<br>
									<p>
										{{ item.SupplierInfo.Name }}
									</p>
								</div>
								<div class="boxsiz fl common-td common-td-110 kc-td">
									<p class="kc-wp">{{ item.SkuViewList[0].Stock }}</p>
									<p class="kc-wp">批次:{{ item.pici }}</p>
								</div>
								<div class="boxsiz fl common-td common-td-100 qdl-td">
									<p class="qdl-p ellipsis">起订量: <span>{{item.qidingshu}}</span></p>
									<p class="bs-p ellipsis">倍数:<span>{{item.beishu}}</span></p>
								</div>
								<div class="boxsiz fl common-td common-td-140 hq-td clear">
									<div class="check-div lb-box dl-check active">
										<i class="iconfont iconselect_multiple_y line-block va-m"></i><span
											class="line-block va-m">{{ item.SkuViewList[0].Name }}</span>
									</div>
								</div>
							</router-link>

							<div class="boxsiz fl common-td common-td-140 sl-td common-td-center">

								<div class="operat-num">
									<el-input-number v-model="item.SkuViewList[0].Num" :min="item.qidingshu" :step="item.beishu" :step-strictly="true"
										:max="item.SkuViewList[0].Stock" size="mini" @change="handleChange">
									</el-input-number>
								</div>
								<p class="num-text">
									合计<span>￥{{ (item.SkuViewList[0].Price * item.SkuViewList[0].Num).toFixed(2) }}</span>
								</p>
							</div>
							<div class="boxsiz fl common-td common-td-100 cz-td common-td-center">
								<a href="javascript:void(0)"
									class="btn common-btn common-btn-red-border now-buy-btn  common-btn-radius"
									data-id="116464406537333" rel="nofollow" @click="payImmediately(item)">立即购买</a>
								<a href="javascript:void(0)"
									class="btn common-btn common-btn-red-white-border now-cart-btn common-btn-radius"
									data-id="1164x 1264406537333" rel="nofollow" @click="addShoppingCart(item)">加购物车</a>
							</div>
						</li>
					</ul>
					<!-- 渲染商品列表 -->
				</div>
			</div>
			<div class="pagination">
				<el-pagination background layout="prev, pager, next" :page-size="pageSize" :total="total"
					@current-change="currentChange"></el-pagination>
			</div>
		</div>
		<!-- 型号end -->



		<!-- 分类标签 -->
		<div class="list">
			<!-- <MyList :list="product" v-if="product.length > 0"></MyList>
			<div v-else class="none-product">
				抱歉没有找到相关的商品，请看看其他的商品
			</div> -->
		</div>

	</div>

	<!-- 分页 -->

	<!-- 分页END -->
	<!-- </div> -->
	<!-- 主要内容区END -->
	<!-- </div> -->
</template>
<script>
	import {
		parseJSON
	} from "jquery";
	import {
		mapActions
	} from "vuex";
	export default {
		data() {
			return {
				categoryClickInfo: {},
				fenleiType: 0,
				categoryList: "", //分类列表
				categoryID: 0, // 分类id
				product: "", // 商品列表
				productList: "",
				total: 0, // 商品总量
				pageSize: 15, // 每页显示的商品数量
				currentPage: 1, //当前页码
				activeName: "-1", // 分类列表当前选中的id
				activeName2: "-1", // 分类列表当前选中的id
				search: "", // 搜索条件
				categoryList2: ["7360元", "14720元", "22080元", "22080元以上"],
				hotGoods: [],
				StartPrice: "", //起始价
				EndPrice: "", //结束价
				lists: ["综合", "销量", "价格升序", "价格降序"],
				curr: 0,
				prie: false,
				num: 1,
			};
		},
		created() {
			// 获取分类列表
			this.getCategory();
		},
		activated() {
			this.activeName = "0"; // 初始化分类列表当前选中的id为-1
			this.activeName2 = "0"; // 初始化分类列表当前选中的id为-1
			this.total = 0; // 初始化商品总量为0
			this.currentPage = 1; //初始化当前页码为1
			console.log(this.$route.query, 203);
			// 如果路由没有传递参数，默认为显示全部商品
			if (Object.keys(this.$route.query).length == 0) {
				this.categoryID = null;
				this.activeName = "0";
				this.activeName2 = "0";
				return;
			}
			// 如果路由传递了categoryID，则显示对应的分类商品
			if (this.$route.query.categoryID != undefined) {
				this.categoryID = this.$route.query.categoryID;
				if (this.categoryID.length == 1) {
					this.activeName = "" + this.categoryID;
				}
				return;
			}
			// 如果路由传递了search，则为搜索，显示对应的分类商品
			if (this.$route.query.search != undefined) {
				this.search = this.$route.query.search;
			}
		},
		watch: {
			// 监听点击了哪个分类标签，通过修改分类id，响应相应的商品
			activeName: function(val) {
				this.categoryID = val;
				if (val == 0) this.categoryID = null;
				// 初始化商品总量和当前页码
				this.total = 0;
				this.currentPage = 1;
				// 更新地址栏链接，方便刷新页面可以回到原来的页面
				this.$router.push({
					path: "/goods",
					query: {
						categoryID: this.categoryID,
					},
				});
			},
			// 监听搜索条件，响应相应的商品
			search: function(val) {
				if (val != "") {
					this.getData(val);
				}
			},
			// 监听分类id，响应相应的商品
			categoryID: function() {
				this.getData();
				this.search = "";
			},
			// 监听路由变化，更新路由传递了搜索条件
			$route: function(val) {
				if (val.path == "/goods") {
					if (val.query.search != undefined) {
						this.activeName = "-1";
						this.currentPage = 1;
						this.total = 0;
						this.search = val.query.search;
					}
					if (val.query.categoryID) {
						this.categoryID = val.query.categoryID;
						this.activeName = "" + this.categoryID;
					} else {
						this.categoryID = null;
						this.activeName = "0";
					}
					this.getData();
				}
			},
		},
		methods: {
			...mapActions(["unshiftShoppingCart", "addShoppingCartNum"]),
			// 返回顶部
			backtop() {
				const timer = setInterval(function() {
					const top =
						document.documentElement.scrollTop || document.body.scrollTop;
					const speed = Math.floor(-top / 5);
					document.documentElement.scrollTop = document.body.scrollTop =
						top + speed;

					if (top === 0) {
						clearInterval(timer);
					}
				}, 20);
			},
			// 页码变化调用currentChange方法
			currentChange(currentPage) {
				this.currentPage = currentPage;
				this.getData();
				this.backtop();
			},
			// 向后端请求分类列表数据
			async getCategory() {
				var res = await this.postdata("/api/News/ClassList", {
					page: 1,
					limit: 9999,
				});
				if (res.code == 200) {
					const val = {
						Id: 0,
						Title: "全部",
					};
					const cate = res.data.items;
					cate.unshift(val);
					this.categoryList = cate;
				}
			},
			// 向后端请求全部商品或分类商品数据
			async getData() {
				// 如果分类列表为空则请求全部商品数据，否则请求分类商品数据
				// const res = await this.postdata("/api/Goods/GoodsList", {
				// 	page: this.currentPage,
				// 	limit: this.pageSize,
				// 	parentId: this.categoryID,
				// 	queryName: this.search,
				// 	"StartPrice": this.StartPrice, //起始价
				// 	"EndPrice": this.EndPrice, //结束价
				// 	orderBy: this.curr
				// });
				// if (res.code == 200) {
				// 	this.product = res.data.items;
				// 	this.product.map(v => {
				// 		v.num = 1
				// 		v.tempIndex = 0
				// 	})
				// 	this.total = res.data.total;
				// 	console.log(this.product)
				// }
				let res2 = await this.postdata("/api/Goods/GoodsList", {
					page: 1,
					limit: 8,
					queryType: this.categoryID,
					queryName: this.search,
					// "IsHot": 1, //热门 1-是
				})
				if (res2.code == 200) {
					this.hotGoods = res2.data.items;
				}
			},
			handleClick(tab, event) {
				// console.log(tab, event);
				if (tab.index == 3) {
					this.StartPrice = tab.label.substring(0, 5)
					this.EndPrice = ''
				} else {
					this.StartPrice = ''
					this.EndPrice = tab.label.substring(0, tab.label.length - 1)
				}

				this.getData();
			},
			lis(index) {
				this.curr = index
				this.getData()
			},
			bdjy() {
				this.curr = 0
				this.getData()
			},
			handleChange(e) {
				console.log(e)
			},
			categoryClick(item) {
				this.categoryID = item.Id
				if (item.Id > 0) {
					var thisitem = JSON.stringify(item)
					localStorage.removeItem('clickGoods')
					localStorage.setItem("clickGoods", thisitem);
				}

				this.$router.push("/goods/connector?Name=" + item.Title + '&&categoryID=' + item.Id + '&&parentId=' + item
					.Id)
			},

			// 加入购物车
			async addShoppingCart(item) {
				// 判断是否登录,没有登录则显示登录组件
				if (!this.$store.getters.getUser) {
					this.$store.dispatch("setShowLogin", true);
					return;
				}
				const res = await this.postdata('/api/Goods/CartOperation', {
					GoodsId: item.Id,
					SkuId: item.SkuViewList[0].Id,
					Num: item.SkuViewList[0].Num
				})
				if (res.code == 200) {
					this.unshiftShoppingCart(res.data);
					this.notifySucceed('加入购物车成功');
				} else {
					this.notifyError(res.msg);
				}
			},
			payImmediately(item) {
				// 判断是否登录,没有登录则显示登录组件
				if (!this.$store.getters.getUser) {
					this.$store.dispatch("setShowLogin", true);
					return;
				}
				var data = {
					GoodsId: item.Id,
					SkuId: item.SkuViewList[0].Id,
					Num: item.SkuViewList[0].Num
				}
				var payProduct = JSON.stringify(data)
				localStorage.setItem("payProduct", payProduct);
				this.$router.push("/ConfirmOrder?type=2")

			},
		},
	};
</script>

<style scoped>
	.goods {
		font-size: 12px;
	}

	/* 面包屑CSS */
	.el-tabs--card .el-tabs__header {
		border-bottom: none;
	}

	.goods .breadcrumb {
		/* height: 50px; */
		/* background-color: white; */
		/* padding-top: 16px; */
		margin-top: -20px;
	}

	.goods .breadcrumb .el-breadcrumb {
		width: 1225px;
		line-height: 30px;
		font-size: 12px;
		margin: 0 auto;
	}

	/* 面包屑CSS END */

	/* 分类标签CSS */
	.goods .nav {
		margin-bottom: 16px;
	}

	.goods .nav .product-nav {
		width: 1225px;
		height: 40px;
		line-height: 40px;
		margin: 0 auto;
	}

	.nav .product-nav .title {
		width: 50px;
		font-size: 16px;
		font-weight: 700;
		float: left;
	}

	/* 分类标签CSS END */

	/* 主要内容区CSS */
	.goods .main {
		margin: 0 auto;
		max-width: 1290px;
	}

	.goods .main .list {
		min-height: 650px;
		padding-top: 14.5px;
		margin-left: -13.7px;
		overflow: auto;
	}

	.goods .pagination {
		height: 50px;
		text-align: center;
	}

	.goods .main .none-product {
		color: #333;
		margin-left: 13.7px;
	}

	/* 主要内容区CSS END */
	.inp {
		width: 50px;
		height: 30px;
		border: 1px solid#f4f4f4;
		padding: 0 6px;
	}

	.bigBox {
		width: 1090px;
		border: 1px solid#f4f4f4;
	}

	.goods .intro-div {
		min-height: 300px;
		padding: 40px;
		background-color: #ffffff;
		margin-bottom: 12px;
		position: relative;
	}

	.goods .intro-div h1 {
		font-size: 18px;
		font-weight: 600;
		position: absolute;
		height: 30px;
		line-height: 30px;
		left: 300px;
		right: 40px;
	}

	.goods .intro-div .seo-div .text {
		color: #333;
		margin-left: 40px;
		width: 850px;
	}

	.goods .intro-div .seo-div img {
		width: 220px;
		height: 220px;
	}

	.goods .intro-div .seo-div .text p {
		margin-top: 30px;
		font-size: 14px;
		line-height: 28px;
		word-wrap: break-word;
		word-break: break-all;
	}

	.goods .filter-section {
		padding: 0 3px 10px 20px;
		background-color: #fff;
	}

	.goods .filter-section .filter-item {
		font-size: 12px;
	}

	.goods .filter-section .filter-item .text {
		font-size: 12px;
		color: #333;
		display: block;
		padding: 10px 5px;
		font-weight: 600;
	}

	.goods .filter-section .filter-item .datas {
		max-height: 140px;
		overflow: auto;
	}

	.goods .filter-section .filter-item .datas .data {
		display: block;
		padding: 0 5px;
		color: #1072cc;
		min-width: 230px;
		border-bottom: 1px solid #f2f3f5;
		white-space: normal;
	}

	.goods .filter-section .filter-item .datas .data a {
		color: #1072cc;
		font-size: 12px;
		height: 27px;
		line-height: 27px;
	}

	.goods .filter-section .filter-item .datas .data span {
		font-weight: normal;
		color: #333;
		font-size: 12px;
		height: 27px;
		line-height: 27px;
	}

	.goods .search-datas {
		padding-top: 10px;
		background-color: #fff;
		margin-top: 10px;
	}

	.goods .search-datas .search-data {
		margin-top: 0;
	}

	.goods .search-datas .search-data {
		background-color: #fff;
		margin-top: 10px;
	}

	.goods .search-datas .search-data .data-article {
		padding: 0 10px 10px;
		color: #333;
	}

	.goods .search-datas .search-data .data-article .header {
		background-color: #f6fcf6;
		height: initial;
		padding-left: 0;
	}

	.goods .search-datas .search-data .data-article .common-td.common-td-132 {
		width: 132px;
	}

	.goods .search-datas .search-data .data-article .common-td.common-td-128 {
		width: 194px;
	}

	.goods .search-datas .search-data .data-article .common-td.common-td-110 {
		width: 110px;
	}

	.goods .search-datas .search-data .data-article .header [stock-th] {
		position: relative;
		padding-left: 25px;
	}

	.goods .search-datas .search-data .data-article .common-td.common-td-100 {
		width: 160px;
	}

	.goods .search-datas .search-data .data-article .common-td.common-td-140 {
		width: 196px;
	}


	.goods .search-datas .search-data .data-article .common-td {
		padding: 10px 0 10px 10px;
	}

	.goods .search-datas .search-data .data-article .body-div-wrapper {
		padding-bottom: 10px;
	}

	.goods .search-datas .search-data .data-article .body-div-wrapper .body-div {
		padding-bottom: 10px;
		position: relative;
		min-height: 80px;
		border-bottom: 1px solid #f2f3f5;
	}

	.goods .search-datas .search-data .data-article .body-div-wrapper .body-div>div {
		padding-bottom: 0;
	}

	.goods .search-datas .search-data .data-article .common-td.model-td {
		padding-bottom: 26px !important;
	}

	.goods .search-datas .search-data .data-article .common-td.model-td .model {
		display: block;
		font-weight: 500;
		position: relative;
	}

	.goods .search-datas .search-data .data-article .common-td.model-td .model .copy-icon {
		cursor: pointer;
		display: none;
		/* background-image: url(../../images/icon_copy.png); */
		background-repeat: no-repeat;
		background-size: 100% 100%;
		width: 22px;
		height: 22px;
	}

	.goods .search-datas .search-data .data-article .common-td.model-td .model a {
		display: block;
		line-height: 24px;
		color: #333;
		word-wrap: break-word;
		word-break: break-all;
	}

	.goods .search-datas .search-data .data-article .common-td.model-td .classify {
		line-height: 24px;
		color: #333;
	}

	.goods .search-datas .search-data .data-article .common-td.model-td .down-div {
		line-height: 24px;
	}

	.goods .search-datas .search-data .data-article .common-td.model-td .down-div .icon-div {
		margin-right: 5px;
		position: relative;
	}

	.goods.search-datas .search-data .data-article .common-td.zzs-td p {
		color: #333;
		overflow: hidden;
		word-wrap: break-word;
		word-break: break-all;
		text-overflow: -o-ellipsis-lastline;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		margin: 0 0 10px 0;
		line-height: 23.5px;
		max-height: 47px;
	}

	.goods .search-datas .search-data .data-article .common-td.zzs-td p a {
		color: #333333;
	}

	.goods.search-datas .search-data .data-article .common-td.kc-td p,
	.search-datas .search-data .data-article .common-td.qdl-td p {
		word-wrap: break-word;
		word-break: break-all;
		line-height: 24px;
	}

	.goods.search-datas .search-data .data-article .common-td.hq-td .check-div {
		cursor: pointer;
		line-height: 24px;
	}

	.goods.search-datas .search-data .data-article .common-td.hq-td .check-div span {
		font-size: 12px;
		margin-left: 4px;
		width: 112px;
	}

	.goods .search-datas .search-data .data-article .common-td.sl-td .num-text span {
		font-size: 12px;
		font-weight: 400;
		text-align: center;
		color: #eb222b;
		line-height: 24px;
	}

	.goods .search-datas .search-data .data-article .common-td.cz-td .btn {
		display: block;
		margin: 10px 0 0 0;
		line-height: 30px;
		font-size: 12px;
		border-radius: 2px;
		width: 72px;
		height: 30px;
		font-weight: 500;
		text-align: center;
	}

	.goods .search-datas .search-data .data-article .common-td.cz-td .btn:first-child {
		margin-top: 0;
	}
</style>
